import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import {
  DxDataGridModule,
  DxFormModule,
  DxCheckBoxModule,
  DxPivotGridModule,
  DxPopupModule,
  DxTemplateModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxButtonModule,
  DxLoadIndicatorModule

} from 'devextreme-angular';
import { PivotReportComponent } from './pages/pivot-report/pivot-report.component';
import { KpiDataComponent } from './pages/kpi-data/kpi-data.component';
import { KpiDataExcludeComponent } from './pages/kpi-data-exclude/kpi-data-exclude.component';
import { Kpi2021Component } from './pages/kpi-2021/kpi-2021.component';
import { Kpi2021CawComponent } from './pages/kpi-2021-caw/kpi-2021-caw.component';
import { Kpi2021JabComponent } from './pages/kpi-2021-jab/kpi-2021-jab.component';
import { Kpi2021StaffComponent } from './pages/kpi-2021-staff/kpi-2021-staff.component';
import { Game2021Component } from './pages/game-2021/game-2021.component';
import { Game2021AllComponent } from './pages/game-2021-all/game-2021-all.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";

//KPI 2023
import { Kpi2023Component } from './pages/kpi-2023/kpi-2023.component';
import { Kpi2023CawComponent } from './pages/kpi-2023-caw/kpi-2023-caw.component';
import { Kpi2023JabComponent } from './pages/kpi-2023-jab/kpi-2023-jab.component';
import { Kpi2023StaffComponent } from './pages/kpi-2023-staff/kpi-2023-staff.component';

//KPI 2024
import { Kpi2024Component } from './pages/kpi-2024/kpi-2024.component';


const routes: Routes = [
  {
    path: 'kpi-data',
    component: KpiDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2024',
    component: Kpi2024Component,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2023',
    component: Kpi2023Component,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2023-caw',
    component: Kpi2023CawComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2023-jab',
    component: Kpi2023JabComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2023-staff',
    component: Kpi2023StaffComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2021',
    component: Kpi2021Component,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2021-caw',
    component: Kpi2021CawComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2021-jab',
    component: Kpi2021JabComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-2021-staff',
    component: Kpi2021StaffComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'game-2021',
    component: Game2021Component,
    canActivate: [AuthGuardService],
  },
  {
    path: 'game-2021-all',
    component: Game2021AllComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'kpi-data-exclude',
    component: KpiDataExcludeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'pivot-report',
    component: PivotReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'display-data',
    component: DisplayDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DxDataGridModule,
    DxFormModule,
    DxCheckBoxModule,
    DxPivotGridModule,
    DxPopupModule,
    DxTemplateModule,
    BrowserModule,
    CommonModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxButtonModule,
    DxLoadIndicatorModule
  ],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    ProfileComponent,
    DisplayDataComponent,
    PivotReportComponent,
    KpiDataComponent,
    KpiDataExcludeComponent,
    //2021
    Kpi2021Component,
    Kpi2021CawComponent,
    Kpi2021JabComponent,
    Kpi2021StaffComponent,
    //2023
    Kpi2023Component,
    Kpi2023CawComponent,
    Kpi2023JabComponent,
    Kpi2023StaffComponent,
    //2024
    Kpi2024Component,
    //Gamification
    Game2021Component,
    Game2021AllComponent
  ],
})
export class AppRoutingModule { }
