export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'KPI Report 2024',
    path: '/kpi-2024',
    icon: 'detailslayout'
  },
  {
    text: 'KPI Report 2023',
    path: '/kpi-2023',
    icon: 'detailslayout'
  }
  // {
  //   text: 'KPI Report Jabatan 2021',
  //   path: '/kpi-2021-jab',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'KPI Report Anggota 2021',
  //   path: '/kpi-2021-staff',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'Gamification Report 2021',
  //   path: '/game-2021',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'All Gamification Report 2021',
  //   path: '/game-2021-all',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'KPI Report Detail 2020',
  //   path: '/display-data',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'KPI Report 2020',
  //   path: '/kpi-data',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'KPI Report 2021',
  //   path: '/kpi-data-exclude',
  //   icon: 'detailslayout'
  // },
  // {
  //   text: 'Pivot Report',
  //   path: '/pivot-report',
  //   icon: 'detailslayout'
  // },

];
