<h2 class="content-block">Training KPI Staff</h2>
<h6 class="content-block">Data Date: {{dataDate}}</h6>

<div class="content-block">
  <div class="dx-card responsive-paddings table-padding">
    <div class="options">
      <p>Table Options:</p>
      <div class="option">
      </div>
    </div>
    <dx-data-grid class="dx-card wide-card" [dataSource]="reports" [showBorders]="false" [focusedRowEnabled]="true"
      [focusedRowIndex]="0" [columnAutoWidth]="true" [allowColumnReordering]="true"
      [rowAlternationEnabled]="true" keyExpr="Username" (onContentReady)="onContentReady($event)" [columns]="[
      'FirstName', 
      'Username', 
      'Position', 
      'Grade',
      'DepartmentCode',
      'UnitCode',
      'UnitName',
      'Branches',
      'AppointmentYear',
      'BirthYear',
      'LearningPlanCode',
      'LearningPlanName',
      'CompletionPercentage',
      'CompletionDate'
      ]">

      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>

     
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
      <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
        <!-- or "select" -->
      </dxo-column-chooser>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 20, 50, 100]"></dxo-pager>
      <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>

      <dxo-summary [calculateCustomSummary]="calculateDL">
        <dxi-group-item name="calculateDigitalLearning" summaryType="custom" valueFormat="decimal"
          displayFormat="Digital Learning: {0}" [showInGroupFooter]="true" showInColumn="Learning Hour">
        </dxi-group-item>
        <dxi-group-item name="calculateDLyt" summaryType="custom" [showInGroupFooter]="true"
          showInColumn="Learning Hour" displayFormat="DL Status: {0} ">
        </dxi-group-item>
        <dxi-group-item column="kpi" summaryType="sum" [showInGroupFooter]="true" [alignByColumn]="true"
          valueFormat="decimal" showInColumn="Learning Hour" displayFormat="Total Learning Hour: {0}">
        </dxi-group-item>
        <dxi-group-item name="calculateGTyt" summaryType="custom" [showInGroupFooter]="true"
          showInColumn="Learning Hour" displayFormat="GT Status: {0} ">
        </dxi-group-item>
      </dxo-summary>

      <dxo-load-panel [enabled]="true"></dxo-load-panel>

    </dx-data-grid>


  </div>
</div>