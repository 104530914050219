import { Component, OnInit, ViewChild } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import * as _ from 'lodash';
import { DxDataGridComponent, DxAccordionComponent } from "devextreme-angular";
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';


@Component({
  selector: 'app-kpi-data',
  templateUrl: './kpi-data.component.html',
  styleUrls: ['./kpi-data.component.scss']
})
export class KpiDataComponent implements OnInit {
  store: ArrayStore;
  dataSource: any;
  priority: any[];
  reports: any[];
  summary: any;
  kpiReports: any[];
  filterValue: Array<any>;
  private _jsonURL = '../../assets/data/data-2020.json';
  // private _jsonURL = '../../assets/data/AB1150.json';
  private _staffJsonURL = '../../assets/data/users-20012021.json';
  private _kpiJsonURL = '../../assets/data/kpi.json';
  pivotGridDataSource: any;
  drillDownDataSource: any;
  dateRange: any;
  kpiCalc: any;
  kpiDetails;



  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(private http: HttpClient) {
    this.dateRange = [
      {
        startDate: '',
        endDate: ''
      }
    ];

    //kpi calculation, can be set as Q1, Q2, Q3, Q4
    this.kpiCalc = "Q4";

    this.getJSON('data').subscribe((data) => {

      // pivot data =========
      var o = data.reduce((a, b) => {
        a[b.Username] = a[b.Username] || [];
        a[b.Username].push({
          FirstName: b.FirstName,
          Grade: b.Grade,
          UnitName: b.UnitName,
          Branches: b.Branches,
          DepartmentCode: b.DepartmentCode,
          UnitCode: b.UnitCode,
          Position: b.Position,
          AppointmentYear: b.AppointmentYear,
          BirthYear: b.BirthYear,
          [b.CourseCategory]: (b.ActualLearningHours) ? Number(b.ActualLearningHours.replace(/[^\d.-]/g, '')) : Number(b.LearningHoursToBeCredited)

        });
        return a;
      }, {});

      var a = Object.keys(o).map(function (k) {
        return {
          Username: k,
          Grade: o[k],
          CourseCategory: totalSum(o[k])
        };
      });

      // reduce data
      var flatten = a.map(x => ({
        Username: x.Username,
        ...x.CourseCategory,
        digitalLearning: this.countDL('sum', x.CourseCategory),
        digitalLearningStatus: this.countDL('stats', x.CourseCategory),
        totalLearningHour: this.countTotal(x.CourseCategory),
        totalStatus: this.countTotalStats(x.Grade, x.CourseCategory),
        totalTrainingHour: this.countTraining(x.CourseCategory),
      }))


      function totalSum(data) {

        const learning = data.reduce((learning, category) => {
          for (const [categoryName, value] of Object.entries(category)) {
            if (categoryName === 'FirstName' || categoryName === 'UnitName' || categoryName === 'Grade' || categoryName === 'Branches' || categoryName === 'DepartmentCode' || categoryName === 'UnitCode' || categoryName === 'Position' || categoryName === 'AppointmentYear' || categoryName === 'BirthYear') {
              learning[categoryName] = value;
            } else {
              if (!learning[categoryName]) {
                learning[categoryName] = 0;
              }
              learning[categoryName] += value;
            }
          }
          return learning;
        }, {});
        return learning;
      }


      //compare all users in LX
      this.getJSON('user').subscribe((data) => {
        // const noRecordStaff = data.filter(x => flatten.indexOf(x) < 0);
        let noRecordStaff = _(data)
          .differenceBy(flatten, 'Username')
          .value();

        noRecordStaff = noRecordStaff.map(x => ({
          ...x,
          KEY: 0,
          "Kursus Anjuran ELC": 0,
          "Kursus Anjuran Jabatan": 0,
          "Kursus Tidak Berprogram": 0,
          "Linkedin Learning": 0,
          "Program Pembangunan": 0,
          digitalLearning: 0,
          totalLearningHour: 0,
          totalTrainingHour: 0,
          digitalLearningStatus: 'T',
          totalStatus: 'T'
        }))

        this.reports = flatten.concat(noRecordStaff);

        this.dataSource = new PivotGridDataSource({
          fields: [
            {
              caption: 'Branches',
              dataField: 'Branches',
              area: 'row',
            },
            {
              caption: 'Unit Name',
              dataField: 'UnitName',
              area: 'row',
            },
            {
              caption: 'Grade',
              dataField: 'Grade',
              area: 'row',
            },
            {
              dataField: 'totalStatus',
              area: 'column',
              caption: 'Total Status'
            },

            {
              dataField: 'totalStatus',
              area: 'data',
              caption: 'Total Status',
              summaryType: 'count',
            }
          ],
          store: this.reports,
        });
      })
    })
  }


  // digital learning
  countDL(type, data) {
    let dl = 0;
    let linkedin = Number(data['Linkedin Learning']) ? Number(data['Linkedin Learning']) : 0;
    let epfTuned = Number(data['EPFTuned']) ? Number(data['EPFTuned']) : 0;
    let grades = data['Grade'];
    let fDL;
    let g4g6DL;
    let g1g3DL;

    if (this.kpiCalc == "Q1") {
      fDL = 0.6;
      g4g6DL = 0.6;
      g1g3DL = 0.3;
    } else if (this.kpiCalc == "Q2") {
      fDL = 3;
      g4g6DL = 3;
      g1g3DL = 1.5;
    } else if (this.kpiCalc == "Q3") {
      fDL = 4.5;
      g4g6DL = 4.5;
      g1g3DL = 2.25;
    } else if (this.kpiCalc == "Q4") {
      fDL = 6;
      g4g6DL = 6;
      g1g3DL = 3;
    }

    if (type == 'sum') {
      dl = linkedin + epfTuned;
      return dl.toFixed(2);
    } else if (type == 'stats') {
      dl = linkedin + epfTuned;
      if (grades == 'G1' || grades == 'G2' || grades == 'G3') {
        if (dl >= g1g3DL) {
          return 'Y';
        } else {
          return 'T'
        }
      } else if (grades == 'G4' || grades == 'G5' || grades == 'G6') {
        if (dl >= g4g6DL) {
          return 'Y';
        } else {
          return 'T'
        }
      } else {
        if (dl >=fDL) {
          return 'Y';
        } else {
          return 'T'
        }
      }
    }
  }

  // total Y/T
  countTotalStats(grades, data) {
    grades = grades[0].Grade
    let bak = data;
    let fDL;
    let fTotal;
    let g4g6DL;
    let g4g6Total;
    let g1g3DL;
    let g1g3Total;
    const dl = Number(this.countDL('sum', data));
    const total = Number(this.countTotal(data));

    if (this.kpiCalc == "Q1") {
      fDL = 0.6;
      fTotal = 3.6;

      g4g6DL = 0.6;
      g4g6Total = 2.4;

      g1g3DL = 0.3;
      g1g3Total = 1.2;
    } else if (this.kpiCalc == "Q2") {
      fDL = 3;
      fTotal = 18;

      g4g6DL = 3;
      g4g6Total = 12;

      g1g3DL = 1.5;
      g1g3Total = 6;
    } else if (this.kpiCalc == "Q3") {
      fDL = 4.5;
      fTotal = 27;

      g4g6DL = 4.5;
      g4g6Total = 18;

      g1g3DL = 2.25;
      g1g3Total = 9;
    } else if (this.kpiCalc == "Q4") {
      fDL = 6;
      fTotal = 36;

      g4g6DL = 6;
      g4g6Total = 24;

      g1g3DL = 3;
      g1g3Total = 12;
    }




    if (grades == 'G1' || grades == 'G2' || grades == 'G3') {
      if (dl >= g1g3DL && total >= g1g3Total) {
        return 'Y';
      } else {
        return 'T'
      }
    } else if (grades == 'G4' || grades == 'G5' || grades == 'G6') {
      if (dl >= g4g6DL && total >= g4g6Total) {
        return 'Y';
      } else {
        return 'T'
      }
    } else {
      if (dl >= fDL && total >= fTotal) {
        return 'Y';
      } else {
        return 'T'
      }
    }
  }

  countTraining(data) {
    let total;
    let values;
    delete data.FirstName;
    delete data.Grade;
    delete data.UnitName;
    delete data.Branches;
    delete data.DepartmentCode;
    delete data.FirstName;
    delete data.Grade;
    delete data.UnitName;
    delete data.Branches;
    delete data.DepartmentCode;
    delete data.UnitCode;
    delete data.Position;
    delete data.AppointmentYear;
    delete data.BirthYear;
    delete data['Linkedin Learning'];
    delete data['Program Pembangunan'];
    delete data['EPFTuned'];

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    values = (Object.values(data).length !== 0) ? Object.values(data) : [0];
    total = values.reduce(reducer);

    return Number(total).toFixed(2);
  }

  countSPI(data) {
    let spi = Number(data['Program Pembangunan']) ? Number(data['Program Pembangunan']) : 0;
    return spi;
  }

  countTotal(data) {
    let total;
    let values;
    delete data.FirstName;
    delete data.Grade;
    delete data.UnitName;
    delete data.Branches;
    delete data.DepartmentCode;
    delete data.UnitCode;
    delete data.Position;
    delete data.AppointmentYear;
    delete data.BirthYear;

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    values = Object.values(data);
    total = values.reduce(reducer);

    return Number(total).toFixed(2);
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  calculateDL(options) {

    if (options.name === 'calculateY') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        options.gile = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue = 'test';
      }
    }
  }

  filterClick(e) {
  }

  ngOnInit() {
  }

  public getJSON(type): Observable<any> {
    if (type == 'data') {
      return this.http.get(this._jsonURL);
    } else if (type == 'user') {
      return this.http.get(this._staffJsonURL);
    } else if (type == 'kpi') {
      return this.http.get(this._kpiJsonURL);
    }
  }
}