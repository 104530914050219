import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";

import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {
  SideNavOuterToolbarModule,
  SideNavInnerToolbarModule,
  SingleCardModule,
} from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService, KpiCalcService,KpiCalc2024Service } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// import {
//   DxPivotGridModule,
//   DxDataGridComponent,
//   DxDataGridModule,
//   DxPopupModule,
//   DxTemplateModule
// } from 'devextreme-angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [AuthService, ScreenService, AppInfoService, KpiCalcService, KpiCalc2024Service],
  bootstrap: [AppComponent],
})
export class AppModule {}
