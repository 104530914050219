import { Component, OnInit, ViewChild } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import * as _ from 'lodash';
import { DxDataGridComponent, DxAccordionComponent } from "devextreme-angular";
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { KpiCalcService } from '../../shared/services';

@Component({
  selector: 'app-game-2021',
  templateUrl: './game-2021.component.html',
  styleUrls: ['./game-2021.component.scss']
})
export class Game2021Component implements OnInit {
  store: ArrayStore;
  dataSource: any;
  priority: any[];
  reports: any[];
  summary: any;
  kpiReports: any[];
  filterValue: Array<any>;
  // connect with the specific JSON 
  // private _jsonURL = '../../assets/data/LP-all.json';
  // private _jsonURL = '../../assets/data/demo-kpi.json';
  private _jsonURL = '../../assets/data/game-07072021.json';
  // private _staffJsonURL = '../../assets/data/demo-user.json';
  private _staffJsonURL = '../../assets/data/users-10062021.json';
  pivotGridDataSource: any;
  drillDownDataSource: any;
  dateRange: any;
  kpiCalc: any;
  kpiDetails;
  lastDept;
  dataDate;

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(private http: HttpClient, private calculator:KpiCalcService ) {

    this.getJSON('data').subscribe((data) => {

      this.dataDate = this.calculator.dataDate();

      // pivot data =========
      var o = data.reduce((a, b) => {
        a[b.Username] = a[b.Username] || [];
        a[b.Username].push({
          FirstName: b.FirstName,
          Grade: b.Grade,
          UnitFullName: b.UnitFullName,
          DepartmentCode: b.DepartmentCode,
          UnitCode: b.UnitCode,
          Position: b.Position,
          Branches: b.Branches,
          BadgeScore: parseInt(b.BadgeScore)
        });
        return a;
      }, {});

      var a = Object.keys(o).map(function (k) {
        return {
          Username: k,
          Grade: o[k],
          TotalGamificationPointEarned: totalSum(o[k]),
        };
      });

      // reduce data
      var flatten = a.map(x => ({
        Username: x.Username,
        ...x.TotalGamificationPointEarned,
      }))

      flatten = flatten.filter(x => x.Username !== 'admin1');

      function totalSum(data) {
        const learning = data.reduce((learning, category) => {
          for (const [BadgeScore, value] of Object.entries(category)) {
            if (BadgeScore === 'FirstName' || BadgeScore === 'UnitFullName' || BadgeScore === 'Grade' || BadgeScore === 'Branches' || BadgeScore === 'DepartmentCode' || BadgeScore === 'UnitCode' || BadgeScore === 'Position' || BadgeScore === 'AppointmentYear' || BadgeScore === 'BirthYear') {
              learning[BadgeScore] = value;
            } else {
              if (!learning[BadgeScore]) {
                learning[BadgeScore] = 0;
              }
              learning[BadgeScore] += value;
            }
          }
          return learning;
        }, {});
        return learning;
      }

      //compare all users in LX
      this.getJSON('user').subscribe((data) => {
        // const noRecordStaff = data.filter(x => flatten.indexOf(x) < 0);
        let noRecordStaff = _(data)
          .differenceBy(flatten, 'Username')
          .value();

        noRecordStaff = noRecordStaff.map(x => ({
          ...x,
          BadgeScore: 0,
        }))

        this.reports = flatten.concat(noRecordStaff);
        var combineData = flatten.concat(noRecordStaff);

        
        this.dataSource = new PivotGridDataSource({
          fields: [
            {
              caption: 'Branches',
              dataField: 'Branches',
              area: 'row',
            },
            {
              caption: 'UnitCode',
              dataField: 'UnitCode',
              area: 'row',
            },
            {
              caption: 'Grade',
              dataField: 'Grade',
              area: 'row',
            },
            {
              dataField: 'individualKPI',
              area: 'column',
              caption: 'Individual KPI'
            },

            {
              dataField: 'individualKPI',
              area: 'data',
              caption: 'Individual KPI',
              summaryType: 'count',
            }
          ],
          store: this.reports,
        });
      })
    })
  }

  insert(arr, index, ...items) {
    return [
      ...arr.slice(0, index),
      ...items,
      ...arr.slice(index)
    ];
  }

  // Count Leadership
  countGP(data) {

    //individual KPI
    let lb = 0;
    let lead01 = Number(data['KPI2021-LB-01']) ? Number(data['KPI2021-LB-01']) : 0;
    let lead02 = Number(data['KPI2021-LB-02']) ? Number(data['KPI2021-LB-02']) : 0;
    let lead03 = Number(data['KPI2021-LB-03']) ? Number(data['KPI2021-LB-03']) : 0;
    let lead04 = Number(data['KPI2021-LB-04']) ? Number(data['KPI2021-LB-04']) : 0;

    lb = lead01 + lead02 + lead03 + lead04

    return lb;
  }
  // Count Leadership
  countLB(data) {

    //individual KPI
    let lb = 0;
    let lead01 = Number(data['KPI2021-LB-01']) ? Number(data['KPI2021-LB-01']) : 0;
    let lead02 = Number(data['KPI2021-LB-02']) ? Number(data['KPI2021-LB-02']) : 0;
    let lead03 = Number(data['KPI2021-LB-03']) ? Number(data['KPI2021-LB-03']) : 0;
    let lead04 = Number(data['KPI2021-LB-04']) ? Number(data['KPI2021-LB-04']) : 0;

    lb = lead01 + lead02 + lead03 + lead04

    return lb;
  }

  // Count Functional 
  countFF(data) {

    //individual KPI
    let ff = 0;
    let ff01 = Number(data['KPI2021-FF-01']) ? Number(data['KPI2021-FF-01']) : 0;
    let ff02 = Number(data['KPI2021-FF-02']) ? Number(data['KPI2021-FF-02']) : 0;
    let ff03 = Number(data['KPI2021-FF-03']) ? Number(data['KPI2021-FF-03']) : 0;
    let ff04 = Number(data['KPI2021-FF-04']) ? Number(data['KPI2021-FF-04']) : 0;
    let ff05 = Number(data['KPI2021-FF-05']) ? Number(data['KPI2021-FF-05']) : 0;
    let ff06 = Number(data['KPI2021-FFf06']) ? Number(data['KPI2021-FFf06']) : 0;

    ff = ff01 + ff02 + ff03 + ff04 + ff05 + ff06;

    return ff;
  }

  // Count Department/Branch
  countDB(data) {

    //individual KPI
    let db = 0;
    let db01 = Number(data['KPI2021-DB-01']) ? Number(data['KPI2021-DB-01']) : 0;
    let db02 = Number(data['KPI2021-DB-02']) ? Number(data['KPI2021-DB-02']) : 0;

    db = db01 + db02;

    return db;
  }

  // Count On-demand
  countET(data) {

    //individual KPI
    let et = 0;
    let et01 = Number(data['KPI2021-ET']) ? Number(data['KPI2021-ET']) : 0;

    et = et01;

    return et;
  }

  // Count On-demand
  countOD(data) {

    //individual KPI
    let od = 0;
    let od01 = Number(data['KPI2021-DB']) ? Number(data['KPI2021-DB']) : 0;

    od = od01;

    return od;
  }

  // Count total LP
  countLP(data) {

    //individual KPI
    let lp = 0;
    // leadership
    let lead01 = Number(data['KPI2021-LB-01']) ? Number(data['KPI2021-LB-01']) : 0;
    let lead02 = Number(data['KPI2021-LB-02']) ? Number(data['KPI2021-LB-02']) : 0;
    let lead03 = Number(data['KPI2021-LB-03']) ? Number(data['KPI2021-LB-03']) : 0;
    let lead04 = Number(data['KPI2021-LB-04']) ? Number(data['KPI2021-LB-04']) : 0;
    // functinal
    let ff01 = Number(data['KPI2021-FF-01']) ? Number(data['KPI2021-FF-01']) : 0;
    let ff02 = Number(data['KPI2021-FF-02']) ? Number(data['KPI2021-FF-02']) : 0;
    let ff03 = Number(data['KPI2021-FF-03']) ? Number(data['KPI2021-FF-03']) : 0;
    let ff04 = Number(data['KPI2021-FF-04']) ? Number(data['KPI2021-FF-04']) : 0;
    let ff05 = Number(data['KPI2021-FF-05']) ? Number(data['KPI2021-FF-05']) : 0;
    let ff06 = Number(data['KPI2021-FFf06']) ? Number(data['KPI2021-FFf06']) : 0;
    // department/branch
    let db01 = Number(data['KPI2021-DB-01']) ? Number(data['KPI2021-DB-01']) : 0;
    let db02 = Number(data['KPI2021-DB-02']) ? Number(data['KPI2021-DB-02']) : 0;
    // epftuned
    let et01 = Number(data['KPI2021-ET']) ? Number(data['KPI2021-ET']) : 0;
    // on-demand
    let od01 = Number(data['KPI2021-DB']) ? Number(data['KPI2021-DB']) : 0;

    // check total LP
    lp = (lead01 + lead02 + lead03 + lead04)
      + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06)
      + (db01 + db02)
      + (et01)
      + (od01);

    return lp;
  }
  // Count individual score
  countScore(data) {

    //individual KPI
    let is = 0;
    // leadership
    let lead01 = Number(data['KPI2021-LB-01']) ? Number(data['KPI2021-LB-01']) : 0;
    let lead02 = Number(data['KPI2021-LB-02']) ? Number(data['KPI2021-LB-02']) : 0;
    let lead03 = Number(data['KPI2021-LB-03']) ? Number(data['KPI2021-LB-03']) : 0;
    let lead04 = Number(data['KPI2021-LB-04']) ? Number(data['KPI2021-LB-04']) : 0;
    // functinal
    let ff01 = Number(data['KPI2021-FF-01']) ? Number(data['KPI2021-FF-01']) : 0;
    let ff02 = Number(data['KPI2021-FF-02']) ? Number(data['KPI2021-FF-02']) : 0;
    let ff03 = Number(data['KPI2021-FF-03']) ? Number(data['KPI2021-FF-03']) : 0;
    let ff04 = Number(data['KPI2021-FF-04']) ? Number(data['KPI2021-FF-04']) : 0;
    let ff05 = Number(data['KPI2021-FF-05']) ? Number(data['KPI2021-FF-05']) : 0;
    let ff06 = Number(data['KPI2021-FFf06']) ? Number(data['KPI2021-FFf06']) : 0;
    // department/branch
    let db01 = Number(data['KPI2021-DB-01']) ? Number(data['KPI2021-DB-01']) : 0;
    let db02 = Number(data['KPI2021-DB-02']) ? Number(data['KPI2021-DB-02']) : 0;
    // epftuned
    let et01 = Number(data['KPI2021-ET']) ? Number(data['KPI2021-ET']) : 0;
    // on-demand
    let od01 = Number(data['KPI2021-DB']) ? Number(data['KPI2021-DB']) : 0;

    //checking mandatory
    if ((lead01 >= 1 || lead02 >= 1 || lead03 >= 1 || lead04 >= 1)
      && (ff01 >= 1 || ff02 >= 1 || ff03 >= 1 || ff04 >= 1 || ff05 >= 1 || ff06 >= 1)
      && (et01 >= 1)) {
      is = (lead01 + lead02 + lead03 + lead04)
        + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06)
        + (db01 + db02)
        + (et01)
        + (od01);
    } else {
      is = 0
    }
    return is;
  }

  // count individuals KPI
  countKPI(data) {

    //individual KPI
    let is = 0;
    let kpi = 'N';
    // leadership
    let lead01 = Number(data['KPI2021-LB-01']) ? Number(data['KPI2021-LB-01']) : 0;
    let lead02 = Number(data['KPI2021-LB-02']) ? Number(data['KPI2021-LB-02']) : 0;
    let lead03 = Number(data['KPI2021-LB-03']) ? Number(data['KPI2021-LB-03']) : 0;
    let lead04 = Number(data['KPI2021-LB-04']) ? Number(data['KPI2021-LB-04']) : 0;
    // functinal
    let ff01 = Number(data['KPI2021-FF-01']) ? Number(data['KPI2021-FF-01']) : 0;
    let ff02 = Number(data['KPI2021-FF-02']) ? Number(data['KPI2021-FF-02']) : 0;
    let ff03 = Number(data['KPI2021-FF-03']) ? Number(data['KPI2021-FF-03']) : 0;
    let ff04 = Number(data['KPI2021-FF-04']) ? Number(data['KPI2021-FF-04']) : 0;
    let ff05 = Number(data['KPI2021-FF-05']) ? Number(data['KPI2021-FF-05']) : 0;
    let ff06 = Number(data['KPI2021-FFf06']) ? Number(data['KPI2021-FFf06']) : 0;
    // department/branch
    let db01 = Number(data['KPI2021-DB-01']) ? Number(data['KPI2021-DB-01']) : 0;
    let db02 = Number(data['KPI2021-DB-02']) ? Number(data['KPI2021-DB-02']) : 0;
    // epftuned
    let et01 = Number(data['KPI2021-ET']) ? Number(data['KPI2021-ET']) : 0;
    // on-demand
    let od01 = Number(data['KPI2021-DB']) ? Number(data['KPI2021-DB']) : 0;

    //checking mandatory
    if ((lead01 >= 1 || lead02 >= 1 || lead03 >= 1 || lead04 >= 1)
      && (ff01 >= 1 || ff02 >= 1 || ff03 >= 1 || ff04 >= 1 || ff05 >= 1 || ff06 >= 1)
      && (et01 >= 1)) {
      is = (lead01 + lead02 + lead03 + lead04)
        + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06)
        + (db01 + db02)
        + (et01)
        + (od01);
    } else {
      is = 0
    }

    kpi = ((is >= 3) ? 'Y' : 'N');
    return kpi;
  }

  deptKPI(data) {
    let currDept = data.DepartmentCode;
    let deptCount = 0;
    // identify current dept
    if (!this.lastDept) {
      let currDept = data.DepartmentCode;
      deptCount = 1;
    } else if (this.lastDept === currDept) {
      deptCount = deptCount + 1;
    }
    return deptCount;
  }

  countDL(type, data) {
    let dl = 0;
    let linkedin = Number(data['Linkedin Learning']) ? Number(data['Linkedin Learning']) : 0;
    let epfTuned = Number(data['EPFTuned']) ? Number(data['EPFTuned']) : 0;
    let grades = data['Grade'];
    let fDL;
    let g4g6DL;
    let g1g3DL;

    if (this.kpiCalc == "Q1") {
      fDL = 0.6;
      g4g6DL = 0.6;
      g1g3DL = 0.3;
    } else if (this.kpiCalc == "Q2") {
      fDL = 3;
      g4g6DL = 3;
      g1g3DL = 1.5;
    } else if (this.kpiCalc == "Q3") {
      fDL = 4.5;
      g4g6DL = 4.5;
      g1g3DL = 2.25;
    } else if (this.kpiCalc == "Q4") {
      fDL = 6;
      g4g6DL = 6;
      g1g3DL = 3;
    }

    if (type == 'sum') {
      dl = linkedin + epfTuned;
      return dl.toFixed(2);
    } else if (type == 'stats') {
      dl = linkedin + epfTuned;
      if (grades == 'G1' || grades == 'G2' || grades == 'G3') {
        if (dl >= g1g3DL) {
          return 'Y';
        } else {
          return 'T'
        }
      } else if (grades == 'G4' || grades == 'G5' || grades == 'G6') {
        if (dl >= g4g6DL) {
          return 'Y';
        } else {
          return 'T'
        }
      } else {
        if (dl >= fDL) {
          return 'Y';
        } else {
          return 'T'
        }
      }
    }
  }

  // total Y/T
  countTotalStats(grades, data) {
    grades = grades[0].Grade
    let bak = data;
    let fDL;
    let fTotal;
    let g4g6DL;
    let g4g6Total;
    let g1g3DL;
    let g1g3Total;
    const dl = Number(this.countDL('sum', data));
    const total = Number(this.countTotal(data));

    if (this.kpiCalc == "Q1") {
      fDL = 0.6;
      fTotal = 3.6;

      g4g6DL = 0.6;
      g4g6Total = 2.4;

      g1g3DL = 0.3;
      g1g3Total = 1.2;
    } else if (this.kpiCalc == "Q2") {
      fDL = 3;
      fTotal = 18;

      g4g6DL = 3;
      g4g6Total = 12;

      g1g3DL = 1.5;
      g1g3Total = 6;
    } else if (this.kpiCalc == "Q3") {
      fDL = 4.5;
      fTotal = 27;

      g4g6DL = 4.5;
      g4g6Total = 18;

      g1g3DL = 2.25;
      g1g3Total = 9;
    } else if (this.kpiCalc == "Q4") {
      fDL = 6;
      fTotal = 36;

      g4g6DL = 6;
      g4g6Total = 24;

      g1g3DL = 3;
      g1g3Total = 12;
    }




    if (grades == 'G1' || grades == 'G2' || grades == 'G3') {
      if (dl >= g1g3DL && total >= g1g3Total) {
        return 'Y';
      } else {
        return 'T'
      }
    } else if (grades == 'G4' || grades == 'G5' || grades == 'G6') {
      if (dl >= g4g6DL && total >= g4g6Total) {
        return 'Y';
      } else {
        return 'T'
      }
    } else {
      if (dl >= fDL && total >= fTotal) {
        return 'Y';
      } else {
        return 'T'
      }
    }
  }

  countTraining(data) {
    let total;
    let values;
    delete data.FirstName;
    delete data.Grade;
    delete data.UnitName;
    delete data.Branches;
    delete data.DepartmentCode;
    delete data.FirstName;
    delete data.Grade;
    delete data.UnitName;
    delete data.Branches;
    delete data.DepartmentCode;
    delete data.UnitCode;
    delete data.Position;
    delete data.AppointmentYear;
    delete data.BirthYear;
    delete data['Linkedin Learning'];
    delete data['Program Pembangunan'];
    delete data['EPFTuned'];

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    values = (Object.values(data).length !== 0) ? Object.values(data) : [0];
    total = values.reduce(reducer);

    return Number(total).toFixed(2);
  }

  countSPI(data) {
    let spi = Number(data['Program Pembangunan']) ? Number(data['Program Pembangunan']) : 0;
    return spi;
  }

  countTotal(data) {
    let total;
    let values;
    delete data.FirstName;
    delete data.Grade;
    delete data.UnitName;
    delete data.Branches;
    delete data.DepartmentCode;
    delete data.UnitCode;
    delete data.Position;
    delete data.AppointmentYear;
    delete data.BirthYear;

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    values = Object.values(data);
    total = values.reduce(reducer);

    return Number(total).toFixed(2);
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  calculateDL(options) {

    if (options.name === 'calculateY') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        options.gile = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue = 'test';
      }
    }
  }

  filterClick(e) {
  }

  ngOnInit() {
  }

  public getJSON(type): Observable<any> {
    if (type == 'data') {
      return this.http.get(this._jsonURL);
    } else if (type == 'user') {
      return this.http.get(this._staffJsonURL);
    }
    // } else if (type == 'kpi') {
    //   return this.http.get(this._kpiJsonURL);
    // }
  }
}

//   public getJSON(): Observable<any> {
//     return this.http.get(this._jsonURL);
//   }
// }