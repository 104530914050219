<h2 class="content-block">Display Data</h2>
<dx-pivot-grid id="KPI" 
  [allowFiltering]="true"  
  [allowSorting]="true"
  [allowFiltering]="true" 
  [allowExpandAll]="true" 
  [height]="800" 
  [showBorders]="true" 
  [dataSource]="dataSource"
  [rowHeaderLayout]="'tree'"
  (onCellClick)="onPivotCellClick($event)"
  showRowTotals="false">
  <dxo-export [enabled]="true" fileName="KPI"></dxo-export>

  <dxo-header-filter [allowSearch]="true" [showRelevantValues]="true" [width]="300" [height]="400">
  </dxo-header-filter>

  <dxo-field-chooser [allowSearch]="true"></dxo-field-chooser>


  <dxo-field-panel [visible]="true">
  </dxo-field-panel>
  
<dxo-scrolling mode="virtual"></dxo-scrolling>
</dx-pivot-grid>

<dx-popup
  [(visible)]="modulePopupVisible"
  [title]="modulePopupTitle"
  [width]="800"
  [height]="500"
  (onShown)="onPopupShown()">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid #drillDownDataGrid
      [width]="760" 
      [height]="400"
      [dataSource]="drillDownDataSource"
      [columns]="['Coursecode', 'CourseName', 'kpi']">
      <dxo-summary>
        <dxi-total-item
            column="CourseCcde"
            summaryType="count">
        </dxi-total-item>
        <dxi-total-item
            column="kpi"
            summaryType="sum"
            valueFormat="decimal">
        </dxi-total-item>
    </dxo-summary>
    </dx-data-grid>
  </div>
</dx-popup>