import { Injectable } from '@angular/core';

@Injectable()
//KPI Calculation 2024
export class KpiCalc2024Service {
    constructor() { }

    // Data
    public dataDate() {
        return 'Jun 2024';
    }

    /* =========================================
    2024 - 4 LP  
    Mandatory (must complete 1 from these 2)
    1. KPI2024-EN
    2. KPI2024-ET

    Optional (acummulated)
    3. KPI2024-FF
    4. KPI2024-OD
    ==============================================*/

    //============================================
    //Count mandatory - Enterprise 
    public countEnt(data) {
        let man = 0;

        //enterprise
        let ent01 = Number(data['KPI2024-EN-01']) ? Number(data['KPI2024-EN-01']) : 0;
        let ent02 = Number(data['KPI2024-EN-02']) ? Number(data['KPI2024-EN-02']) : 0;
        let ent03 = Number(data['KPI2024-EN-03']) ? Number(data['KPI2024-EN-03']) : 0;
        let ent04 = Number(data['KPI2024-EN-04']) ? Number(data['KPI2024-EN-04']) : 0;
        let ent05 = Number(data['KPI2024-EN-05']) ? Number(data['KPI2024-EN-05']) : 0;
        let ent06 = Number(data['KPI2024-EN-06']) ? Number(data['KPI2024-EN-06']) : 0;
        let ent07 = Number(data['KPI2024-EN-07']) ? Number(data['KPI2024-EN-07']) : 0;
        let ent08 = Number(data['KPI2024-EN-08']) ? Number(data['KPI2024-EN-08']) : 0;
        let ent09 = Number(data['KPI2024-EN-09']) ? Number(data['KPI2024-EN-09']) : 0;

        man = ent01 + ent02 + ent03 + ent04 + ent05 + ent06 + ent07 + ent08 + ent09;

        return man;
    }

    //Count mandatory - EPFTuned
    public countET(data) {
        let et = 0;

        let et01 = Number(data['KPI2024-ET-01']) ? Number(data['KPI2024-ET-01']) : 0;
        let et02 = Number(data['KPI2024-ET-02']) ? Number(data['KPI2024-ET-02']) : 0;
        let et03 = Number(data['KPI2024-ET-03']) ? Number(data['KPI2024-ET-03']) : 0;
        let et04 = Number(data['KPI2024-ET-04']) ? Number(data['KPI2024-ET-04']) : 0;
        let et05 = Number(data['KPI2024-ET-05']) ? Number(data['KPI2024-ET-05']) : 0;
        let et06 = Number(data['KPI2024-ET-06']) ? Number(data['KPI2024-ET-06']) : 0;

        // console.log('data', data);
        // console.log('et01', et01);
        // console.log('et02', et03);
        // console.log('et03', et03);
        // console.log('et04', et04);
        // console.log('et05', et05);
        // console.log('et06', et06);

        // Grade >F (!Operations !Investment)
        if (!data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et01;
            //Grade G !Operations !Investment)
        } else if (data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et02;
            //Grade G Operations
        } else if (!data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et03;
            //Grade >F Operations
        } else if (data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et04;
            //Grade G Investment
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et05;
            //Grade >F Investment
        } else if (data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et06;
        }
        return et;
    }
    //============================================

    //============================================
    //Count Functional (DB)
    public countFunc(data) {

        //individual KPI
        let db = 0;
        let db01 = Number(data['KPI2024-DB-01']) ? Number(data['KPI2024-DB-01']) : 0;
        let db02 = Number(data['KPI2024-DB-02']) ? Number(data['KPI2024-DB-02']) : 0;
        let db03 = Number(data['KPI2024-DB-03']) ? Number(data['KPI2024-DB-03']) : 0;
        let db04 = Number(data['KPI2024-DB-04']) ? Number(data['KPI2024-DB-04']) : 0;
        let db05 = Number(data['KPI2024-DB-05']) ? Number(data['KPI2024-DB-05']) : 0;

        db = db01 + db02 + db03 + db04 + db05;
        return db;
    }

    // Count On-demand (OD)
    public countOD(data) {

        //individual KPI
        let od = 0;
        let od01 = Number(data['KPI2024-OD']) ? Number(data['KPI2024-OD']) : 0;

        od = od01;

        return od;
    }

    // Count total LP
    public countLP(data) {

        //individual KPI
        let lp = 0;

        //mandatory - enterprise
        let ent = 0;
        let ent01 = Number(data['KPI2024-EN-01']) ? Number(data['KPI2024-EN-01']) : 0;
        let ent02 = Number(data['KPI2024-EN-02']) ? Number(data['KPI2024-EN-02']) : 0;
        let ent03 = Number(data['KPI2024-EN-03']) ? Number(data['KPI2024-EN-03']) : 0;
        let ent04 = Number(data['KPI2024-EN-04']) ? Number(data['KPI2024-EN-04']) : 0;
        let ent05 = Number(data['KPI2024-EN-05']) ? Number(data['KPI2024-EN-05']) : 0;
        let ent06 = Number(data['KPI2024-EN-06']) ? Number(data['KPI2024-EN-06']) : 0;
        let ent07 = Number(data['KPI2024-EN-07']) ? Number(data['KPI2024-EN-07']) : 0;
        let ent08 = Number(data['KPI2024-EN-08']) ? Number(data['KPI2024-EN-08']) : 0;
        let ent09 = Number(data['KPI2024-EN-09']) ? Number(data['KPI2024-EN-09']) : 0;

        ent = ent01 + ent02 + ent03 + ent04 + ent05 + ent06 + ent07 + ent08 + ent09;

        //mandatory - EPFTuned
        let et = 0;
        let et01 = Number(data['KPI2024-ET-01']) ? Number(data['KPI2024-ET-01']) : 0;
        let et02 = Number(data['KPI2024-ET-02']) ? Number(data['KPI2024-ET-02']) : 0;
        let et03 = Number(data['KPI2024-ET-03']) ? Number(data['KPI2024-ET-03']) : 0;
        let et04 = Number(data['KPI2024-ET-04']) ? Number(data['KPI2024-ET-04']) : 0;
        let et05 = Number(data['KPI2024-ET-05']) ? Number(data['KPI2024-ET-05']) : 0;
        let et06 = Number(data['KPI2024-ET-06']) ? Number(data['KPI2024-ET-06']) : 0;

        // console.log('et01', et01);
        // console.log('et02', et03);
        // console.log('et03', et03);
        // console.log('et04', et04);
        // console.log('et05', et05);
        // console.log('et06', et06);
        // console.log('et01', et01);

        // Grade >F (!Operations !Investment)
        if (!data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et01;
            //Grade G !Operation Division !Investment)
        } else if (data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et02;
            //Grade G Operation Division
        } else if (!data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et03;
            //Grade >F Operation Division
        } else if (data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et04;
            //Grade G Investment
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et05;
            //Grade >F Investment
        } else if (data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et06;
        }
        //Optional - Functional
        let db = 0;
        let db01 = Number(data['KPI2024-DB-01']) ? Number(data['KPI2024-DB-01']) : 0;
        let db02 = Number(data['KPI2024-DB-02']) ? Number(data['KPI2024-DB-02']) : 0;
        let db03 = Number(data['KPI2024-DB-03']) ? Number(data['KPI2024-DB-03']) : 0;
        let db04 = Number(data['KPI2024-DB-04']) ? Number(data['KPI2024-DB-04']) : 0;
        let db05 = Number(data['KPI2024-DB-05']) ? Number(data['KPI2024-DB-05']) : 0;
        db = db01 + db02 + db03 + db04 + db05;

        // on-demand
        let od01 = Number(data['KPI2024-OD']) ? Number(data['KPI2024-OD']) : 0;

        //check total LP
        lp = (ent)
            + (et)
            + (db)
            + (od01);
        // console.log('ent', ent);
        // console.log('et', et);
        // console.log('db', db);
        // console.log('od01', od01);
        return lp;
    }

    // Count individual score
    public countScore(data) {

        //individual KPI
        let is = 1;
        let totalEN = 0;
        let totalET = 0
        let totalDB = 0;
        let totalOD = 0;
        let totalLP = 0;

        //mandatory - enterprise
        let ent01 = Number(data['KPI2024-EN-01']) ? Number(data['KPI2024-EN-01']) : 0;
        let ent02 = Number(data['KPI2024-EN-02']) ? Number(data['KPI2024-EN-02']) : 0;
        let ent03 = Number(data['KPI2024-EN-03']) ? Number(data['KPI2024-EN-03']) : 0;
        let ent04 = Number(data['KPI2024-EN-04']) ? Number(data['KPI2024-EN-04']) : 0;
        let ent05 = Number(data['KPI2024-EN-05']) ? Number(data['KPI2024-EN-05']) : 0;
        let ent06 = Number(data['KPI2024-EN-06']) ? Number(data['KPI2024-EN-06']) : 0;
        let ent07 = Number(data['KPI2024-EN-07']) ? Number(data['KPI2024-EN-07']) : 0;
        let ent08 = Number(data['KPI2024-EN-08']) ? Number(data['KPI2024-EN-08']) : 0;
        let ent09 = Number(data['KPI2024-EN-09']) ? Number(data['KPI2024-EN-09']) : 0;

        //mandatory - EPFTuned
        let et = 0;
        let et01 = Number(data['KPI2024-ET-01']) ? Number(data['KPI2024-ET-01']) : 0;
        let et02 = Number(data['KPI2024-ET-02']) ? Number(data['KPI2024-ET-02']) : 0;
        let et03 = Number(data['KPI2024-ET-03']) ? Number(data['KPI2024-ET-03']) : 0;
        let et04 = Number(data['KPI2024-ET-04']) ? Number(data['KPI2024-ET-04']) : 0;
        let et05 = Number(data['KPI2024-ET-05']) ? Number(data['KPI2024-ET-05']) : 0;
        let et06 = Number(data['KPI2024-ET-06']) ? Number(data['KPI2024-ET-06']) : 0;

        // Grade >F (!Operations !Investment)
        if (!data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et01;
            //Grade G !Operations !Investment)
        } else if (data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et02;
            //Grade G Operations
        } else if (!data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et03;
            //Grade >F Operations
        } else if (data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et04;
            //Grade G Investment
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et05;
            //Grade >F Investment
        } else if (data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et06;
        }
        //Optional - Functional
        let db01 = Number(data['KPI2024-DB-01']) ? Number(data['KPI2024-DB-01']) : 0;
        let db02 = Number(data['KPI2024-DB-02']) ? Number(data['KPI2024-DB-02']) : 0;
        let db03 = Number(data['KPI2024-DB-03']) ? Number(data['KPI2024-DB-03']) : 0;
        let db04 = Number(data['KPI2024-DB-04']) ? Number(data['KPI2024-DB-04']) : 0;
        let db05 = Number(data['KPI2024-DB-05']) ? Number(data['KPI2024-DB-05']) : 0;

        // on-demand
        let od01 = Number(data['KPI2024-OD']) ? Number(data['KPI2024-OD']) : 0;

        //checking mandatory
        // mandatory - enterprise/epftuned
        if (ent01 >= 1 || ent02 >= 1 || ent03 >= 1 || ent04 >= 1 || ent05 >= 1 || ent06 >= 1 || ent07 >= 1 || ent08 >= 1 || ent09 >= 1) {
            totalEN = 1;
        } else {
            totalEN = 0;
        }

        // epftuned
        if (et01 >= 1 || et02 >= 1 || et03 >= 1 || et04 >= 1 || et05 >= 1 || et06 >= 1) {
            totalET = 1
        } else {
            totalET = 0;
        }

        // functional
        if (db01 > 0 || db02 > 0 || db03 > 0 || db04 > 0 || db05 > 0) {
            totalDB = db01 + db02 + db03 + db04 + db05;
        } else {
            totalDB = 0;
        }

        totalOD = od01;

        totalLP = totalET + totalDB + totalEN + totalOD;

        if (totalET == 1 && totalEN == 1 && totalLP >= 5) {
            is = 5;
        } else if (totalET == 1 && totalEN == 1 && totalLP == 1) {
            is = 3;
        } else if (totalET == 1 && totalEN == 1 && totalLP == 2) {
            is = 4;
        } else if (totalET == 1 && totalEN == 1 && totalLP == 3) {
            is = 3;
        } else if (totalET == 1 && totalEN == 1 && totalLP < 3) {
            is = 2;
        } else if (totalET == 1 && totalEN == 0 && totalLP >= 1) {
            is = 1;
        } else if (totalET == 0 && totalEN == 1 && totalLP >= 1) {
            is = 1;
        } else {
            is = 1;
        }


        // is = totalET + totalDB + totalEN + totalOD;


        // console.log('totalET', totalET);
        // console.log('totalDB', totalDB);
        // console.log('totalEN', totalEN);
        // console.log('totalOD', totalOD);

        return is;
    }

    // count individuals KPI
    public countKPI(data) {

        //individual KPI
        let is = 0;
        let totalLP;
        let kpi = 'N';
        //mandatory - enterprise
        let ent01 = Number(data['KPI2024-EN-01']) ? Number(data['KPI2024-EN-01']) : 0;
        let ent02 = Number(data['KPI2024-EN-02']) ? Number(data['KPI2024-EN-02']) : 0;
        let ent03 = Number(data['KPI2024-EN-03']) ? Number(data['KPI2024-EN-03']) : 0;
        let ent04 = Number(data['KPI2024-EN-04']) ? Number(data['KPI2024-EN-04']) : 0;
        let ent05 = Number(data['KPI2024-EN-05']) ? Number(data['KPI2024-EN-05']) : 0;
        let ent06 = Number(data['KPI2024-EN-06']) ? Number(data['KPI2024-EN-06']) : 0;
        let ent07 = Number(data['KPI2024-EN-07']) ? Number(data['KPI2024-EN-07']) : 0;
        let ent08 = Number(data['KPI2024-EN-08']) ? Number(data['KPI2024-EN-08']) : 0;
        let ent09 = Number(data['KPI2024-EN-09']) ? Number(data['KPI2024-EN-09']) : 0;

        //mandatory - EPFTuned
        let et = 0;
        let et01 = Number(data['KPI2024-ET-01']) ? Number(data['KPI2024-ET-01']) : 0;
        let et02 = Number(data['KPI2024-ET-02']) ? Number(data['KPI2024-ET-02']) : 0;
        let et03 = Number(data['KPI2024-ET-03']) ? Number(data['KPI2024-ET-03']) : 0;
        let et04 = Number(data['KPI2024-ET-04']) ? Number(data['KPI2024-ET-04']) : 0;
        let et05 = Number(data['KPI2024-ET-05']) ? Number(data['KPI2024-ET-05']) : 0;
        let et06 = Number(data['KPI2024-ET-06']) ? Number(data['KPI2024-ET-06']) : 0;

        // Grade >F (!Operations !Investment)
        if (!data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et01;
            //Grade G !Operations !Investment)
        } else if (data['Grade'].includes('G') && ((!data['Branches'].includes('Investment Division')) && !data['Branches'].includes('Operation Division') && !data['Branches'].includes('Branches'))) {
            et = et02;
            //Grade G Operations
        } else if (!data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et03;
            //Grade >F Operations
        } else if (data['Grade'].includes('G') && (data['Branches'].includes('Operation Division') || data['Branches'].includes('Branches'))) {
            et = et04;
            //Grade G Investment
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et05;
            //Grade >F Investment
        } else if (data['Grade'].includes('G') && data['Branches'].includes('Investment Division')) {
            et = et06;
        }
        let db01 = Number(data['KPI2024-DB-01']) ? Number(data['KPI2024-DB-01']) : 0;
        let db02 = Number(data['KPI2024-DB-02']) ? Number(data['KPI2024-DB-02']) : 0;
        let db03 = Number(data['KPI2024-DB-03']) ? Number(data['KPI2024-DB-03']) : 0;
        let db04 = Number(data['KPI2024-DB-04']) ? Number(data['KPI2024-DB-04']) : 0;
        let db05 = Number(data['KPI2024-DB-05']) ? Number(data['KPI2024-DB-05']) : 0;

        // on-demand
        let od01 = Number(data['KPI2024-OD']) ? Number(data['KPI2024-OD']) : 0;

        //checking mandatory
        //check if enterprise & epftuned exist 
        let checkent = 0;
        let checket = 0;
        if (ent01 >= 1 || ent02 >= 1 || ent03 >= 1 || ent04 >= 1 || ent05 >= 1 || ent06 >= 1 || ent07 >= 1 || ent08 >= 1 || ent09 >= 1) {
            checkent = 1;
        }

        if (et01 >= 1 || et02 >= 1 || et03 >= 1 || et04 >= 1 || et05 >= 1 || et06 >= 1) {
            checket = 1;
        }

        if ((ent01 >= 1 || ent02 >= 1 || ent03 >= 1 || ent04 >= 1 || ent05 >= 1 || ent06 >= 1 || ent07 >= 1 || ent08 >= 1 || ent09 >= 1)
            && (et01 >= 1 || et02 >= 1 || et03 >= 1 || et04 >= 1 || et05 >= 1 || et06 >= 1)) {
            totalLP = (ent01 + ent02 + ent03 + ent04 + ent05 + ent06 + ent07 + ent08 + ent09)
                + (et)
                + (db01 + db02 + db03 + db04 + db05)
                + (od01);
        } else {
            totalLP = (ent01 + ent02 + ent03 + ent04 + ent05 + ent06 + ent07 + ent08 + ent09)
                + (et)
        }

        if (checkent == 1 && checket == 1 && totalLP >= 5) {
            is = 5;
        } else if (checkent == 1 && checket == 1 && totalLP < 3) {
            is = 2;
        } else if (checkent == 1 && checket == 1 && totalLP == 3) {
            is = 3;
        } else if (checkent == 1 && checket == 1 && totalLP == 2) {
            is = 1
        } else if (checkent == 1 && checket == 0 && totalLP >= 1) {
            is = 1;
        } else if (checkent == 0 && checket == 1 && totalLP >= 1) {
            is = 1;
        }

        kpi = ((is >= 3) ? 'Y' : 'N');
        return kpi;
    }

}
