import { Component, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import * as _ from 'lodash';
import Pivot from 'quick-pivot';
import { KpiCalcService } from '../../shared/services';

@Component({
  templateUrl: 'game-2021-all.component.html',
  styleUrls: ['./game-2021-all.component.scss']
})
export class Game2021AllComponent implements OnInit {
  // store: LocalStore;
  store: ArrayStore;
  dataSource: any;
  priority: any[];
  reports: any[];
  kpiReports: any[];
  filterValue: Array<any>;
  private _jsonURL = '../../assets/data/game-07072021.json';
  dataDate;
  isLoadIndicatorVisible;
  //JSON

  constructor(private http: HttpClient, private calculator: KpiCalcService) {
    this.dataDate = this.calculator.dataDate();
    this.isLoadIndicatorVisible = true;
    this.getJSON().subscribe((data) => {
      this.reports = data.map(x=>{
        x.BadgeScore = x.BadgeScore ? Number(x.BadgeScore.replace(/[^\d.-]/g, '')) : Number(x.BadgeScore);
        return x;
      }

      );

      this.reports = this.reports.filter(x => x.Username !== 'admin1')
      if (this.reports) {
        this.store = new ArrayStore({
          key: 'staffId',
          data: this.reports,
        });
      }
    });
  }

  calculateCellValue(data) {
    return [data.staffId, data.name].join(" ");
  }

  calculateDL(options) {
    if (options.name === 'calculateDigitalLearning') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (
          options.value.category === 'Linkedin Learning' ||
          options.value.category === 'EPFTuned'
        ) {
          options.totalValue = options.totalValue + options.value.kpi;
        }
      }
    }
    if (options.name === 'calculateDLyt') {

      if (options.summaryProcess === 'start') {

        options.totalValue = 0;
        options.gile = 0;

      } else if (options.summaryProcess === 'calculate') {
        if (
          options.value.category === 'Linkedin Learning' ||
          options.value.category === 'EPFTuned'
        ) {

          options.gile = options.gile + options.value.kpi;
          options.totalValue = (options.gile > 2) ? 'Y' : 'T';

        }

      }
    }
    if (options.name === 'calculateGTyt') {

      if (options.summaryProcess === 'start') {

        options.totalValue = 0;
        options.gt = 0;
        options.dl = 0;

      } else if (options.summaryProcess === 'calculate') {
        if (options.value.category === 'Linkedin Learning' || options.value.category === 'EPFTuned') {
          options.dl = options.dl + options.value.kpi;
        }
        options.gt = options.gt + options.value.kpi;
        if (options.value.grade === 'G1' || options.value.grade === 'G2' || options.value.grade === 'G3') {
          if (options.gt >= 6 && options.dl >= 1.5) {
            options.totalValue = 'Y'
          } else {
            options.totalValue = 'T'
          }
        } else if (options.value.grade === 'G4' || options.value.grade === 'G5' || options.value.grade === 'G6') {
          if (options.gt >= 12 && options.dl >= 3) {
            options.totalValue = 'Y'
          } else {
            options.totalValue = 'T'
          }
        } else {
          if (options.gt >= 18 && options.dl >= 3) {
            options.totalValue = 'Y'
          } else {
            options.totalValue = 'T'
          }
        }
      }
    }
  }

  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  ngOnInit() { }
}
