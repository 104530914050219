import { Component, OnInit, ViewChild } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import * as _ from 'lodash';
import { DxDataGridComponent, DxAccordionComponent } from "devextreme-angular";
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { KpiCalcService } from '../../shared/services';

@Component({
  selector: 'app-kpi-2021-caw',
  templateUrl: './kpi-2021-caw.component.html',
  styleUrls: ['./kpi-2021-caw.component.scss']
})
export class Kpi2021CawComponent implements OnInit {
  store: ArrayStore;
  dataSource: any;
  priority: any[];
  reports: any[];
  summary: any;
  kpiReports: any[];
  filterValue: Array<any>;
  // connect with the specific JSON 
  private _jsonURL = '../../assets/data/lp-07072021.json';
  private _staffJsonURL = '../../assets/data/users-07072021.json';
  pivotGridDataSource: any;
  drillDownDataSource: any;
  dateRange: any;
  kpiCalc: any;
  kpiDetails;
  lastDept;
  dataDate;


  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(private http: HttpClient, private calculator: KpiCalcService) {

    this.dataDate = this.calculator.dataDate();

    this.getJSON('data').subscribe((data) => {

      // pivot data =========
      var o = data.reduce((a, b) => {
        a[b.Username] = a[b.Username] || [];
        a[b.Username].push({
          FirstName: b.FirstName,
          Grade: b.Grade,
          UnitFullName: b.UnitFullName,
          DepartmentCode: b.DepartmentCode,
          UnitCode: b.UnitCode,
          Position: b.Position,
          Branches: b.Branches,
          [b.LearningPlanCode.replace(/\s+/g, '')]: (b.LearningPlanEnrollmentStatus === 'Completed') ? 1 : 0
        });
        return a;
      }, {});

      var a = Object.keys(o).map(function (k) {
        return {
          Username: k,
          Grade: o[k],
          LPCategory: totalSum(o[k]),
        };
      });

      // reduce data
      var flatten = a.map(x => ({
        Username: x.Username,
        ...x.LPCategory,
        // LP category calc
        LeadershipBehavioral: this.calculator.countLB(x.LPCategory),
        FutureFit: this.calculator.countFF(x.LPCategory),
        EPFTuned: this.calculator.countET(x.LPCategory),
        Functional: this.calculator.countDB(x.LPCategory),
        OnDemand: this.calculator.countOD(x.LPCategory),
        // LP total count
        totalLP: this.calculator.countLP(x.LPCategory),
        individualScore: this.calculator.countScore(x.LPCategory),
        individualKPI: this.calculator.countKPI(x.LPCategory),
        // departmentKPI: this.deptKPI(x.LPCategory)
      }))

      function totalSum(data) {
        const learning = data.reduce((learning, category) => {
          for (const [categoryName, value] of Object.entries(category)) {
            if (categoryName === 'FirstName' || categoryName === 'UnitFullName' || categoryName === 'Grade' || categoryName === 'Branches' || categoryName === 'DepartmentCode' || categoryName === 'UnitCode' || categoryName === 'Position' || categoryName === 'AppointmentYear' || categoryName === 'BirthYear') {
              learning[categoryName] = value;
            } else {
              if (!learning[categoryName]) {
                learning[categoryName] = 0;
              }
              learning[categoryName] += value;
            }
          }
          return learning;
        }, {});
        return learning;
      }

      //compare all users in LX
      this.getJSON('user').subscribe((data) => {
        // const noRecordStaff = data.filter(x => flatten.indexOf(x) < 0);
        let noRecordStaff = _(data)
          .differenceBy(flatten, 'Username')
          .value();

        noRecordStaff = noRecordStaff.map(x => ({
          ...x,
          LeadershipBehavioral: 0,
          FutureFit: 0,
          EPFTuned: 0,
          Functional: 0,
          OnDemand: 0,
          totalLP: 0,
          individualScore: 0,
          individualKPI: 'N',
          departmentKPI: 'N',
          TotalDeptYCount: 0,
          TotalDeptCount: 0,
          deptKPIperc: 0,
          totalStatus: 'N',
        }))

        var combineData = flatten.concat(noRecordStaff);

        // count dept based on unit code
        var deptCount = [];

        for (var dept of combineData) {
          var entryFound = false;
          var tempObj = {
            UnitCode: dept.UnitCode,
            UnitName: dept.UnitName,
            TotalDeptCount: 1
          };

          for (var item of deptCount) {
            if (item.UnitCode === tempObj.UnitCode) {
              item.TotalDeptCount++;
              entryFound = true;
              break;
            }
          }
          if (!entryFound) {
            deptCount.push(tempObj);
          }
        }

        // combine dept count to master list
        flatten = flatten.map(x => {
          const obj = deptCount.find(o => o.UnitCode === x.UnitCode);
          return { ...x, ...obj }
        })

        // count individual Y based on dept (unit code)
        var deptYCount = [];
        for (var dept of flatten) {

          var tempY = {
            id: dept.Username,
            code: dept.UnitCode,
            iKPI: dept.individualKPI,
            TotalDeptYCount: 1,
          };

          for (var item of deptYCount) {
            if (item.code === tempY.code && tempY.iKPI == 'Y') {
              item.TotalDeptYCount++;
            }
          }

          deptYCount.push(tempY);

        }

        // combine to master list
        flatten = flatten.map(x => {
          const obj = deptYCount.find(o => o.code === x.UnitCode);
          return { ...x, ...obj }
        })

        // get dept KPI 
        var deptKPI = [];
        for (var dept of flatten) {
          var entryFound = false;
          var tempX = {
            UnitCode: dept.UnitCode,
            TotalDeptYCount: dept.TotalDeptYCount,
            TotalDeptCount: dept.TotalDeptCount,
            deptKPIperc: 0,
            departmentKPIcount: 1,
            departmentKPI: 'N'
          };

          for (var item of deptKPI) {
            if (item.UnitCode === tempX.UnitCode) {
              item.deptKPIperc = (tempX.TotalDeptYCount / tempX.TotalDeptCount) * 100;
              if (item.deptKPIperc >= 95) {
                item.departmentKPIcount = 5;
                item.departmentKPI = 'Y'
              } else if (item.deptKPIperc > 90 && item.deptKPIperc < 95) {
                item.departmentKPIcount = 4;
                item.departmentKPI = 'Y'
              } else if (item.deptKPIperc === 90) {
                item.departmentKPIcount = 3;
                item.departmentKPI = 'Y'
              } else if (item.deptKPIperc > 85 && item.deptKPIperc < 90) {
                item.departmentKPIcount = 2;
                item.departmentKPI = 'N'
              } else if (item.deptKPIperc <= 85) {
                item.departmentKPIcount = 1;
                item.departmentKPI = 'N'
              }

            }
          }
          if (!entryFound) {
            deptKPI.push(tempX);
          }
        }

        flatten = flatten.map(x => {
          const obj = deptKPI.find(o => o.UnitCode === x.UnitCode);
          return { ...x, ...obj }
        })

        // get total status
        var totalStatus = []
        for (var totalstat of flatten) {
          var entryFound = false;
          var tempS = {
            UnitCode: totalstat.UnitCode,
            indKPI: totalstat.individualKPI,
            deptKPI: totalstat.departmentKPI,
            totalStatus: 'N'
          };

          for (var item of totalStatus) {
            if (item.UnitCode === tempS.UnitCode) {
              if (item.indKPI === 'Y' && item.deptKPI === 'Y') {
                item.totalStatus = 'Y'
              } else {
                item.totalStatus = 'N'
              }
            }
          }
          if (!entryFound) {
            totalStatus.push(tempS);
          }
        }

        flatten = flatten.map(x => {
          const obj = totalStatus.find(o => o.UnitCode === x.UnitCode);
          return { ...x, ...obj }
        })

        // 3300905502

        flatten = flatten.filter(x => x.UnitCode != '');
        flatten = flatten.filter(x => x.LearningPlanEnrollmentStatus != 'Completed');
        this.reports = flatten.concat(noRecordStaff);

        //filter reports for cawangan (branches)
        this.reports = this.reports.filter(x => x.Branches.includes('Branches'))

        this.dataSource = new PivotGridDataSource({
          fields: [
            {
              caption: 'Branches',
              dataField: 'Branches',
              area: 'row',
            },
            {
              caption: 'UnitCode',
              dataField: 'UnitCode',
              area: 'row',
            },
            {
              caption: 'Grade',
              dataField: 'Grade',
              area: 'row',
            },
            {
              dataField: 'individualKPI',
              area: 'column',
              caption: 'Individual KPI'
            },

            {
              dataField: 'individualKPI',
              area: 'data',
              caption: 'Individual KPI',
              summaryType: 'count',
            }
          ],
          store: this.reports,
        });
      })
    })
  }

  insert(arr, index, ...items) {
    return [
      ...arr.slice(0, index),
      ...items,
      ...arr.slice(index)
    ];
  }


  onContentReady(e) {
    e.component.option("loadPanel.enabled", false);
  }


  filterClick(e) {
  }

  ngOnInit() {
  }

  public getJSON(type): Observable<any> {
    if (type == 'data') {
      return this.http.get(this._jsonURL);
    } else if (type == 'user') {
      return this.http.get(this._staffJsonURL);
    }
  }
}
