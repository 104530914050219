<h2 class="content-block">Home</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">
        <div class="logos-container">
            <img width="100%" src="../../../assets/img/main-banner.jpeg">
        </div>

        <p>Welcome to <b>LX Companion App</b>!</p>

    </div>
</div>
