<h2 class="content-block">KPI 2021 by Division</h2>
<h6 class="content-block">Data Date: {{dataDate}}</h6>
<div class="content-block">
    <div class="dx-card responsive-paddings table-padding">
        <!-- <div id="form-container">
            <dx-form id="form" [colCount]="3" [formData]="dateRange">
                <dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ value: null, width: '100%' }">
                </dxi-item>
                <dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ value: null, width: '100%' }">
                </dxi-item>
                <div class="dx-fieldset">

                    <div class="dx-field">
                        <div class="dx-field-value">
                            <div class="buttons">
                                <dx-button icon="check" type="success" text="Done" (onClick)="filterClick()">
                                </dx-button>
                                <div>
                                    <dx-button
                                        stylingMode="contained"
                                        text="Contained"
                                        type="normal"
                                        [width]="120"
                                        (onClick)="filterClick()">
                                    </dx-button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </dx-form>
        </div> -->


        <dx-data-grid class="dx-card wide-card" [dataSource]="reports" [showBorders]="false" [focusedRowEnabled]="true"
            [focusedRowIndex]="0" [columnAutoWidth]="true" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
            [allowColumnReordering]="true" [allowColumnResizing]="true" keyExpr="Username"
            (onContentReady)="onContentReady($event)" [columns]="[
      'FirstName', 
      'Username', 
      'Position',
      'Grade',
      'DepartmentCode',
      'UnitCode',
      'UnitFullName',
      'Branches',
      'LeadershipBehavioral',
      'FutureFit',
      'EPFTuned',
      'Functional',
      'OnDemand',
      'totalLP',
      'individualScore',
      'individualKPI',
      'departmentKPI',
      'totalStatus'
      ]">
      <dxo-summary>
        <dxi-total-item
            column="DepartmentCode"
            summaryType="count">
        </dxi-total-item>
      
    </dxo-summary>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [showInfo]="true" [allowedPageSizes]="[10, 20, 50, 100]">
            </dxo-pager>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <!-- <dxo-scrolling mode="virtual"></dxo-scrolling> -->
            <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>

            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
            </dxo-column-chooser>


            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

        </dx-data-grid>

    </div>
</div>
<h2 class="content-block">Summary</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings table-padding">
        <dx-pivot-grid id="KPI" [allowFiltering]="true" [allowSorting]="true" [allowFiltering]="true"
            [allowExpandAll]="true" [height]="800" [showBorders]="true" [dataSource]="dataSource"
            [rowHeaderLayout]="'tree'" showRowTotals="false">
            <dxo-export [enabled]="true" fileName="KPI"></dxo-export>

            <dxo-header-filter [allowSearch]="true" [showRelevantValues]="true" [width]="300" [height]="400">
            </dxo-header-filter>

            <dxo-field-chooser [allowSearch]="true"></dxo-field-chooser>

            <dxo-field-panel [visible]="true">
            </dxo-field-panel>

        </dx-pivot-grid>
    </div>
</div>