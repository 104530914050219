import { Injectable } from '@angular/core';

@Injectable()
export class KpiCalcService {
    constructor() { }


    public getLPData() {
        // return '../../assets/data/lp-q2-2023.json';
    }

    public getUserData() {
        //return '../../assets/data/users_21022023.json';
    }

    // Data
    public dataDate() {
        return '31/12/2023';
    }

    // Count Leadership
    public countLB(data) {

        let lb = 0;
        //KPI 2023
        let lead01 = Number(data['KPI2023-LB-01']) ? Number(data['KPI2023-LB-01']) : 0;
        let lead02 = Number(data['KPI2023-LB-02']) ? Number(data['KPI2023-LB-02']) : 0;
        let lead03 = Number(data['KPI2023-LB-03']) ? Number(data['KPI2023-LB-03']) : 0;
        let lead04 = Number(data['KPI2023-LB-04']) ? Number(data['KPI2023-LB-04']) : 0;
        let lead05 = Number(data['KPI2023-LB-05']) ? Number(data['KPI2023-LB-05']) : 0;
        let lead06 = Number(data['KPI2023-LB-06']) ? Number(data['KPI2023-LB-06']) : 0;
        lb = lead01 + lead02 + lead03 + lead04 + lead05 + lead06;

        return lb;
    }

    // Count Functional 
    public countFF(data) {

        //individual KPI
        let ff = 0;
        let ff01 = Number(data['KPI2023-FF-01']) ? Number(data['KPI2023-FF-01']) : 0;
        let ff02 = Number(data['KPI2023-FF-02']) ? Number(data['KPI2023-FF-02']) : 0;
        let ff03 = Number(data['KPI2023-FF-03']) ? Number(data['KPI2023-FF-03']) : 0;
        let ff04 = Number(data['KPI2023-FF-04']) ? Number(data['KPI2023-FF-04']) : 0;
        let ff05 = Number(data['KPI2023-FF-05']) ? Number(data['KPI2023-FF-05']) : 0;
        let ff06 = Number(data['KPI2023-FF-06']) ? Number(data['KPI2023-FF-06']) : 0;
        let ff07 = Number(data['KPI2023-FF-07']) ? Number(data['KPI2023-FF-07']) : 0;

        ff = ff01 + ff02 + ff03 + ff04 + ff05 + ff06 + ff07;

        return ff;
    }

    // Count Department/Branch
    public countDB(data) {

        //individual KPI
        let db = 0;
        let db01 = Number(data['KPI2023-DB-01']) ? Number(data['KPI2023-DB-01']) : 0;
        let db02 = Number(data['KPI2023-DB-02']) ? Number(data['KPI2023-DB-02']) : 0;

        db = db01 + db02;

        return db;
    }

    // Count On-demand
    public countET(data) {

        //individual KPI
        let et = 0;
        let et01 = Number(data['KPI2023-ET-01']) ? Number(data['KPI2023-ET-01']) : 0;
        let et02 = Number(data['KPI2023-ET-02']) ? Number(data['KPI2023-ET-02']) : 0;
        let et03 = Number(data['KPI2023-ET-03']) ? Number(data['KPI2023-ET-03']) : 0;
        let et04 = Number(data['KPI2023-ET-04']) ? Number(data['KPI2023-ET-04']) : 0;

        if (data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et04;
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et03;
        } else if (data['Grade'].includes('G') && !data['Branches'].includes('Investment')) {
            et = et02;
        } else  {
            et = et01;
        }
        return et;
    }

    // Count On-demand
    public countOD(data) {

        //individual KPI
        let od = 0;
        let od01 = Number(data['KPI2023-OD']) ? Number(data['KPI2023-OD']) : 0;

        od = od01;

        return od;
    }

    // Count total LP
    public countLP(data) {

        //individual KPI
        let lp = 0;


        // leadership
        let lead01 = Number(data['KPI2023-LB-01']) ? Number(data['KPI2023-LB-01']) : 0;
        let lead02 = Number(data['KPI2023-LB-02']) ? Number(data['KPI2023-LB-02']) : 0;
        let lead03 = Number(data['KPI2023-LB-03']) ? Number(data['KPI2023-LB-03']) : 0;
        let lead04 = Number(data['KPI2023-LB-04']) ? Number(data['KPI2023-LB-04']) : 0;
        let lead05 = Number(data['KPI2023-LB-05']) ? Number(data['KPI2023-LB-05']) : 0;
        let lead06 = Number(data['KPI2023-LB-06']) ? Number(data['KPI2023-LB-06']) : 0;
        // functinal
        let ff01 = Number(data['KPI2023-FF-01']) ? Number(data['KPI2023-FF-01']) : 0;
        let ff02 = Number(data['KPI2023-FF-02']) ? Number(data['KPI2023-FF-02']) : 0;
        let ff03 = Number(data['KPI2023-FF-03']) ? Number(data['KPI2023-FF-03']) : 0;
        let ff04 = Number(data['KPI2023-FF-04']) ? Number(data['KPI2023-FF-04']) : 0;
        let ff05 = Number(data['KPI2023-FF-05']) ? Number(data['KPI2023-FF-05']) : 0;
        let ff06 = Number(data['KPI2023-FF-06']) ? Number(data['KPI2023-FF-06']) : 0;
        let ff07 = Number(data['KPI2023-FF-07']) ? Number(data['KPI2023-FF-07']) : 0;
        // department/branch
        let db01 = Number(data['KPI2023-DB-01']) ? Number(data['KPI2023-DB-01']) : 0;
        let db02 = Number(data['KPI2023-DB-02']) ? Number(data['KPI2023-DB-02']) : 0;

         //individual KPI
        let et = 0;
        let et01 = Number(data['KPI2023-ET-01']) ? Number(data['KPI2023-ET-01']) : 0;
        let et02 = Number(data['KPI2023-ET-02']) ? Number(data['KPI2023-ET-02']) : 0;
        let et03 = Number(data['KPI2023-ET-03']) ? Number(data['KPI2023-ET-03']) : 0;
        let et04 = Number(data['KPI2023-ET-04']) ? Number(data['KPI2023-ET-04']) : 0;

        if (data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et04;
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et03;
        } else if (data['Grade'].includes('G') && !data['Branches'].includes('Investment')) {
            et = et02;
        } else  {
            et = et01;
        }
     
        // on-demand
        let od01 = Number(data['KPI2023-OD']) ? Number(data['KPI2023-OD']) : 0;

        // check total LP
            lp = (lead01 + lead02 + lead03 + lead04 + lead05 + lead06)
                + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06 + ff07)
                + (db01 + db02)
                + (et)
                + (od01);

        return lp;
    }
    // Count individual score
    public countScore(data) {

        //individual KPI
        let is = 0;
        let totalLB = 0;
        let totalFF = 0;
        let totalET = 0;

        // leadership
        let lead01 = Number(data['KPI2023-LB-01']) ? Number(data['KPI2023-LB-01']) : 0;
        let lead02 = Number(data['KPI2023-LB-02']) ? Number(data['KPI2023-LB-02']) : 0;
        let lead03 = Number(data['KPI2023-LB-03']) ? Number(data['KPI2023-LB-03']) : 0;
        let lead04 = Number(data['KPI2023-LB-04']) ? Number(data['KPI2023-LB-04']) : 0;
        let lead05 = Number(data['KPI2023-LB-05']) ? Number(data['KPI2023-LB-05']) : 0;
        let lead06 = Number(data['KPI2023-LB-06']) ? Number(data['KPI2023-LB-06']) : 0;
        // future fit
        let ff01 = Number(data['KPI2023-FF-01']) ? Number(data['KPI2023-FF-01']) : 0;
        let ff02 = Number(data['KPI2023-FF-02']) ? Number(data['KPI2023-FF-02']) : 0;
        let ff03 = Number(data['KPI2023-FF-03']) ? Number(data['KPI2023-FF-03']) : 0;
        let ff04 = Number(data['KPI2023-FF-04']) ? Number(data['KPI2023-FF-04']) : 0;
        let ff05 = Number(data['KPI2023-FF-05']) ? Number(data['KPI2023-FF-05']) : 0;
        let ff06 = Number(data['KPI2023-FF-06']) ? Number(data['KPI2023-FF-06']) : 0;
        let ff07 = Number(data['KPI2023-FF-07']) ? Number(data['KPI2023-FF-07']) : 0;
        // epftuned
        let et=0;
        let et01 = Number(data['KPI2023-ET-01']) ? Number(data['KPI2023-ET-01']) : 0;
        let et02 = Number(data['KPI2023-ET-02']) ? Number(data['KPI2023-ET-02']) : 0;
        let et03 = Number(data['KPI2023-ET-03']) ? Number(data['KPI2023-ET-03']) : 0;
        let et04 = Number(data['KPI2023-ET-04']) ? Number(data['KPI2023-ET-04']) : 0;

        if (data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et04;
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et03;
        } else if (data['Grade'].includes('G') && !data['Branches'].includes('Investment')) {
            et = et02;
        } else  {
            et = et01;
        }
     
        //supporting LP
        // department/branch
        let db01 = Number(data['KPI2023-DB-01']) ? Number(data['KPI2023-DB-01']) : 0;
        let db02 = Number(data['KPI2023-DB-02']) ? Number(data['KPI2023-DB-02']) : 0;
        // on-demand
        let od01 = Number(data['KPI2023-OD']) ? Number(data['KPI2023-OD']) : 0;

        //filter epftuned 
        // if (data['Grade'].includes('G')) {
        //     et01 = 0;
        // } else {
        //     et02 = 0;
        // }

        //checking mandatory
        if ((lead01 >= 1 || lead02 >= 1 || lead03 >= 1 || lead04 >= 1 || lead05 >= 1 || lead06 >= 1)
            && (ff01 >= 1 || ff02 >= 1 || ff03 >= 1 || ff04 >= 1 || ff05 >= 1 || ff06 >= 1 || ff07 >= 1)
            && (et01 >= 1 || et02 >= 1 || et03 >= 1 || et04 >= 1)) {
            is = (lead01 + lead02 + lead03 + lead04 + lead05 + lead06)
                + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06 + ff07)
                + (et)
                + (db01 + db02)
                + (od01);
            is = (is > 5) ? 5 : is;
        } else {

            // leadership
            if (lead02 > 0 || lead02 > 0 || lead03 > 0 || lead04 > 0 || lead05 > 0 || lead06 > 0) {
                totalLB = 1;
            } else {
                totalLB = 0;
            }

            // future fit
            if (ff01 > 0 || ff02 > 0 || ff03 > 0 || ff04 > 0 || ff05 > 0 || ff06 > 0 || ff07 > 0) {
                totalFF = 1;
            } else {
                totalFF = 0;
            }
            // epftuned
            if (et01 > 0 || et02 > 0 || et03 > 0 || et04 > 0) {
                totalET = 1
            } else {
                totalET = 0;
            }
            is = totalET + totalFF + totalLB;
        }
        return is;
    }

    // count individuals KPI
    public countKPI(data) {

        //individual KPI
        let is = 0;
        let kpi = 'N';
        // leadership
        let lead01 = Number(data['KPI2023-LB-01']) ? Number(data['KPI2023-LB-01']) : 0;
        let lead02 = Number(data['KPI2023-LB-02']) ? Number(data['KPI2023-LB-02']) : 0;
        let lead03 = Number(data['KPI2023-LB-03']) ? Number(data['KPI2023-LB-03']) : 0;
        let lead04 = Number(data['KPI2023-LB-04']) ? Number(data['KPI2023-LB-04']) : 0;
        let lead05 = Number(data['KPI2023-LB-05']) ? Number(data['KPI2023-LB-05']) : 0;
        let lead06 = Number(data['KPI2023-LB-06']) ? Number(data['KPI2023-LB-06']) : 0;
        // functinal
        let ff01 = Number(data['KPI2023-FF-01']) ? Number(data['KPI2023-FF-01']) : 0;
        let ff02 = Number(data['KPI2023-FF-02']) ? Number(data['KPI2023-FF-02']) : 0;
        let ff03 = Number(data['KPI2023-FF-03']) ? Number(data['KPI2023-FF-03']) : 0;
        let ff04 = Number(data['KPI2023-FF-04']) ? Number(data['KPI2023-FF-04']) : 0;
        let ff05 = Number(data['KPI2023-FF-05']) ? Number(data['KPI2023-FF-05']) : 0;
        let ff06 = Number(data['KPI2023-FF-06']) ? Number(data['KPI2023-FF-06']) : 0;
        let ff07 = Number(data['KPI2023-FF-07']) ? Number(data['KPI2023-FF-07']) : 0;
        // department/branch
        let db01 = Number(data['KPI2023-DB-01']) ? Number(data['KPI2023-DB-01']) : 0;
        let db02 = Number(data['KPI2023-DB-02']) ? Number(data['KPI2023-DB-02']) : 0;
        // epftuned
        let et = 0;
        let et01 = Number(data['KPI2023-ET-01']) ? Number(data['KPI2023-ET-01']) : 0;
        let et02 = Number(data['KPI2023-ET-02']) ? Number(data['KPI2023-ET-02']) : 0;
        let et03 = Number(data['KPI2023-ET-03']) ? Number(data['KPI2023-ET-03']) : 0;
        let et04 = Number(data['KPI2023-ET-04']) ? Number(data['KPI2023-ET-04']) : 0;

        if (data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et04;
        } else if (!data['Grade'].includes('G') && data['Branches'].includes('Investment')) {
            et = et03;
        } else if (data['Grade'].includes('G') && !data['Branches'].includes('Investment')) {
            et = et02;
        } else  {
            et = et01;
        }
     
        // on-demand
        let od01 = Number(data['KPI2023-OD']) ? Number(data['KPI2023-OD']) : 0;

        //checking mandatory
        if ((lead01 >= 1 || lead02 >= 1 || lead03 >= 1 || lead04 >= 1 || lead05 >= 1 || lead06 > 1)
            && (ff01 >= 1 || ff02 >= 1 || ff03 >= 1 || ff04 >= 1 || ff05 >= 1 || ff06 >= 1 || ff07 >= 1)
            && (et01 >= 1 || et02 >= 1 || et03 >= 1 || et04 >= 1)) {
            is = (lead01 + lead02 + lead03 + lead04 + lead05 + lead06)
                + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06 + ff07)
                + (db01 + db02)
                + (et)
                + (od01);
        } else {
            is = (lead01 + lead02 + lead03 + lead04 + lead05 + lead06)
                + (ff01 + ff02 + ff03 + ff04 + ff05 + ff06 + ff07)
                + (et)
        }

        kpi = ((is >= 3) ? 'Y' : 'N');
        return kpi;
    }

}
