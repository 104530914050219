import {
  NgModule,
  Component,
  ViewChild,
  AfterViewInit,
  enableProdMode,
  OnInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import ArrayStore from 'devextreme/data/array_store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

@Component({
  selector: 'app-pivot-report',
  templateUrl: './pivot-report.component.html',
  styleUrls: ['./pivot-report.component.scss'],
})
export class PivotReportComponent implements OnInit {
  @ViewChild('drillDownDataGrid') drillDownDataGrid: DxDataGridComponent;

  pivotGridDataSource: any;
  drillDownDataSource: any;
  modulePopupVisible = false;
  modulePopupTitle = '';
  dataSource: any;
  private _jsonURL = '../../assets/data/data.json';
  reports: any[];
  store: ArrayStore;

  constructor(private http: HttpClient) {
    this.getJSON().subscribe((data) => {
      this.reports = data;
      if (this.reports) {
        this.store = new ArrayStore({
          key: 'staffId',
          data: this.reports,
          // Other ArrayStore options go here
        });
        this.dataSource = new PivotGridDataSource({
          fields: [
            {
              caption: 'Name',
              dataField: 'name',
              area: 'row',
              width: 300,
            },
            {
              caption: 'Staff Id',
              dataField: 'staffId',
              area: 'row',
              width: 300,
            },
            {
              caption: 'Grade',
              dataField: 'grade',
              area: 'row',
            },
            {
              caption: 'Unit',
              dataField: 'unitName',
              area: 'row',
            },
            {
              dataField: 'category',
              area: 'column',
              filterType: 'include',
              filterValues: ['Linkedin Learning', 'EPFTuned'],
              format: 'decimal',
            },
            {
              dataField: 'courseName',
              area: 'column',
              caption: 'Course Name',
            },

            {
              caption: 'KPI',
              dataField: 'kpi',
              dataType: 'number',
              summaryType: 'sum',
              area: 'data',
              visible: true,
              format: {
                type: 'decimal',
              },
            },
            // {
            //   area: 'data',
            //   caption: 'Digital Learning',
            //   summaryType: 'custom',
            //   format: {
            //     type: 'decimal'
            //   },
            //   calculateCustomSummary: function (options) {
            //     if (options.summaryProcess == 'start') {
            //       options.totalValue = 0; //Sum
            //       options.sum2 = 0; //Sum of squared values
            //       options.n = 0;
            //     }
            //     if (options.summaryProcess == 'calculate') {
            //       if (options.value.category == 'Linkedin Learning' || options.value.category == 'EPFTuned') {
            //         options.totalValue += options.value;
            //         // options.sum2 += Math.pow(options.value.kpi, 2);
            //         options.sum2 += Math.pow(options.value, 2);
            //         options.n++;
            //       }
            //     }
            //     if (options.summaryProcess == 'finalize') {
            //       options.totalValue =
            //         options.sum2 - Math.pow(options.totalValue, 2) / options.n;
            //       options.totalValue /= options.n - 1;
            //     }
            //   },
            // },
          ],
          store: this.reports,
        });
      }
    });
  }

  staffId(data) {
    return data.name + ' (' + data.staffId + ' - ' + data.unitName + ')';
  }

  digitalLearning(data) {
    var ll = 0;
    var tuned = 0;
    var dl = 0;

    if (data.category == 'Linkedin Learning') {
      ll = data.kpi;
    } else if (data.category == 'EPFTuned') {
      tuned = data.kpi;
    } else {
      dl = 0;
    }

    if (ll || tuned) {
      dl = ll + tuned;
      return dl;
    } else {
      return dl;
    }
  }

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  onPivotCellClick(e) {
    if (e.area == 'data') {
      var rowPathLength = e.cell.rowPath.length,
        rowPathName = e.cell.rowPath[rowPathLength - 1];

      this.drillDownDataSource = this.dataSource.createDrillDownDataSource(
        e.cell
      );
      this.modulePopupTitle =
        (rowPathName ? rowPathName : 'Total') + ' Drill Down Data';
      this.modulePopupVisible = true;
    }
  }

  onPopupShown() {
    this.drillDownDataGrid.instance.updateDimensions();
  }

  ngOnInit() { }
}
